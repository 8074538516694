// Show remaining character limit on textarea fields
$("textarea").on("input", function () {
  textAreaFormat($(this));
});

$(document).ready(function () {
  $("textarea").each(function () {
    textAreaFormat($(this));
  });
});

$(window).on("resize", function () {
  $("textarea").each(function () {
    textAreaFormat($(this));
  });
});

function textAreaFormat(textarea) {
  const chars = textarea.val().length;
  const maxlength = textarea.attr("maxlength");
  const remaining = maxlength - chars;

  if (maxlength) {
    const label = textarea.next("span");

    label.text(remaining);

    if (chars >= maxlength) {
      label.addClass("text-red-700");
      return false;
    } else {
      label.removeClass("text-red-700");
    }
  }

  textarea.css("height", textarea.prop("scrollHeight") + 10 + "px");
}

// Remove assets
$(".removeAsset").click(function () {
  $(this).parent().remove();
});

// Drag and drop fields
document.querySelectorAll(".drag-container").forEach(function (i) {
  if (i) {
    initDragDrop(i);
  }
});

function initDragDrop(target) {
  const dragArea = target.querySelector(".drag-area");
  const dragOverlay = target.querySelector(".drag-overlay");
  const fileInputButton = target.querySelector(".file-input-button");
  const fileInput = target.querySelector(".file-input");

  const fileTypes = fileInput.dataset.fileTypes.split(" ");

  let documentImages = target.querySelector(".document-images");
  let errorMessage = target.querySelector(".error-message");

  // Files array to check whether there is any file selected or not
  let documentFileObj = {
    fileName: [],
  };

  // If user click on button then the input also gets clicked
  fileInputButton.onclick = (e) => {
    e.preventDefault();
    fileInput.click();
  };

  // When the input changes update our file list
  fileInput.addEventListener("change", (e) => {
    updateFileList(e.target.files, fileTypes);
  });

  // If user drags over the input
  dragArea.addEventListener("dragover", (e) => {
    e.preventDefault();
    dragOverlay.classList.remove("opacity-0");
  });

  // If user drag leaves the input
  dragArea.addEventListener("dragleave", () => {
    dragOverlay.classList.add("opacity-0");
  });

  // If user drops file on the input
  dragArea.addEventListener("drop", (e) => {
    e.preventDefault();
    dragOverlay.classList.add("opacity-0");

    // Update our file list
    updateFileList(e.dataTransfer.files, fileTypes);
  });

  const updateFileList = (files, fileTypes = []) => {
    for (var i = 0; i < files.length; i++) {
      const fileName = files[i].name;
      const fileSize = files[i].size;
      const fileType = files[i].type.split("/").pop();

      let sizeInMB = Number.parseFloat(fileSize / (1024 * 1024)).toFixed(2);

      // If the file size is greater than 5mb, show an error message
      if (sizeInMB > 5) {
        showError("The file size should be less than 5mb");
      } else {
        // Check file type is in allow list, otherwise show an error message
        if (fileTypes.includes(files[i].type)) {
          hideError();

          let newDocument = document.createElement("li");

          newDocument.setAttribute(
            "class",
            "py-3 flex justify-between items-center md:items-end text-xs md:text-sm text-slate-700 border-b-2 border-slate-100 gap-1 document-file"
          );

          newDocument.innerHTML = `
            <p class="whitespace-nowrap overflow-hidden text-ellipsis w-40">${fileName}</p>
            <p>${fileType}</p>
            <p>${sizeInMB}mb</p>
            <p>Uploaded</p>
            `;

          // Show the uploaded files
          documentImages.append(newDocument);
          documentFileObj["fileName"].push(fileName);
        } else {
          showError("That file type is not allowed");
        }
      }
    }
  };

  const showError = (errorCode) => {
    errorMessage.textContent = errorCode;
    errorMessage.classList.remove("hidden");
  };

  const hideError = () => {
    errorMessage.classList.add("hidden");
  };
}
