let map;
let geocoder;

async function initMap(target) {
  // Request needed libraries.
  const { Map } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

  // The map
  map = new Map(target, {
    center: { lat: 53.450959271623354, lng: -2.2499002239871726 },
    zoom: 6,
    mapId: target.id,
  });

  // Geocode
  geocoder = new google.maps.Geocoder();

  // The markers
  for (const location of locations) {
    geocodeAddress(location);
  }
}

// Geocode the address and create a marker at that position
function geocodeAddress(location) {
  geocoder
    .geocode({ address: location.address })
    .then((result) => {
      const { results } = result;

      const infowindow = new google.maps.InfoWindow({
        content: location.content,
        ariaLabel: location.description,
      });

      const marker = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: results[0].geometry.location,
        title: location.description,
      });

      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
        });
      });

      return results;
    })
    .catch((e) => {
      // alert("Geocode was not successful for the following reason: " + e);
      return;
    });
}

// Look for all map containers
const buildMaps = window.addEventListener(
  "load",
  (event) => {
    document.querySelectorAll(".google-map").forEach(function (i) {
      if (i) {
        initMap(i);
      }
    });
  },
  false
);

export { buildMaps };
