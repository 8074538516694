import * as nav from "./modules/navigation.js";
import * as table from "./modules/tables.js";
import animateOnScroll from "./modules/observer.js";
import initialize from "./modules/map.js";
import * as carousel from "./modules/carousels.js";
import * as forms from "./modules/forms.js";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
  },
  false
);

// Application form
$("#freeform-1").dirty({
  preventLeaving: true,
});

// Payment form
$("#freeform-2").dirty({
  preventLeaving: true,
});

$(".toggle-text").click(function () {
  $(this).prev().toggleClass("line-clamp-4");
  $(this).text() == "Read More"
    ? $(this).text("Read Less")
    : $(this).text("Read More");
});

// lightbox
$(".lightbox-toggle").click(function () {
  $(this).parent().parent().next(".lightbox").removeClass("hidden");
});

$(".lightbox").click(function () {
  $(this).addClass("hidden");
});

// Loading indicator
$(".show-loader").on("click", function () {
  $(this).next(".loader").removeClass("hidden");
});

// Dynamic fields
const memberStatus = $("#ctaMemberStatus");

memberStatus.change(function () {
  const selectedVal = $(this).val();
  const target = $("#ctaMemberStatus-show");

  fieldConditionalToggle(selectedVal, target, "yes");
});

const roleInProject = $("#roleInProject");

roleInProject.change(function () {
  const selectedVal = $(this).val();
  const target = $("#pleaseSpecifyRole-row");

  fieldConditionalToggle(selectedVal, target, "Other");
});

// Show on load
fieldConditionalToggle(memberStatus.val(), $("#ctaMemberStatus-show"), "yes");

fieldConditionalToggle(
  roleInProject.val(),
  $("#pleaseSpecifyRole-row"),
  "Other"
);

// Function
function fieldConditionalToggle(select, target, condition) {
  if (select == condition) {
    target.removeClass("hidden");
  } else {
    target.addClass("hidden");
  }
}

// Confirm completion
$(".submit-application").submit(function () {
  var c = confirm(
    "Once you submit your application, you will no longer be able to edit it. Are you sure you want to submit it?"
  );
  return c; //you can just return c because it will be true or false
});
