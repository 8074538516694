import DataTable from "datatables.net-dt";
import "datatables.net-responsive-dt";

DataTable.datetime("DD/MM/YYYY");

let submissionsTable = new DataTable("#submissionList", {
  responsive: true,
  order: [[1, "desc"]],
  pagingType: "numbers",
  pageLength: 100,
  lengthChange: false,
  fnDrawCallback: function (oSettings) {
    if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
      $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
    } else {
      $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
    }
  },
});

let assessorsTable = new DataTable("#assessorList", {
  responsive: false,
  order: [[0, "desc"]],
  pagingType: "numbers",
  pageLength: 100,
  lengthChange: false,
  fnDrawCallback: function (oSettings) {
    if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
      $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
    } else {
      $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
    }
  },
});

let assessmentTable = new DataTable("#assessmentList", {
  responsive: false,
  order: [[0, "desc"]],
  pagingType: "numbers",
  pageLength: 250,
  lengthChange: false,
  fnDrawCallback: function (oSettings) {
    if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
      $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
    } else {
      $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
    }
  },
});

export { submissionsTable, assessorsTable, assessmentTable };
