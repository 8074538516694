import Swiper from "swiper/swiper-bundle.mjs";

const schemePhotos = new Swiper(".scheme-photos", {
  centeredSlides: true,
  slidesPerView: 1,
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

$(".lightbox-toggle").click(function () {
  const index = $(this).parent().index();

  schemePhotos.attachEvents();
  schemePhotos.slideTo(index, 10);
});

$(".lightbox").click(function () {
  schemePhotos.slideReset(10);
});

const productCarousel = new Swiper(".product-carousel", {
  slidesPerView: 1,
  loop: true,
  effect: "fade",
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const heroCarousel = new Swiper(".hero-carousel", {
  slidesPerView: 1,
  loop: true,
  effect: "fade",
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

export { schemePhotos, productCarousel, heroCarousel };
